import { Link } from 'gatsby';
import type { FC } from 'react';
import bharathlogo from '../../images/aatmanirbhar-bharat-logo.png';
import logo from '../../images/logoNew.png';
import BurgerMenu from './burger-nav';
import HeaderCallButton from './call-btn';
import LayoutNav from './navigation';

const LayoutHeader: FC = () => {
  return (
    <header
      className="flex items-center justify-between fixed top-0 left-0 right-0 z-50
      bg-white shadow-sm min-h-20 max-w-[2100px] mx-auto border-b "
    >
      <div className="px-6 mx-auto w-full ">
        <div className="flex justify-between items-center">
          <Link className="flex space-x-2 flex-nowrap items-center" to="/">
            <img alt="logo" src={logo} width={208} />
          </Link>

          <LayoutNav />
          <div />

          <BurgerMenu />
        </div>
      </div>

      <div className="xl:flex space-x-4 hidden items-center pr-6">
        <div className="min-w-14">
          <img alt="bharath" height={58} src={bharathlogo} width={48} />
        </div>
        <HeaderCallButton />
      </div>
    </header>
  );
};

export default LayoutHeader;
