'use client';

import { GoChevronDown } from '@react-icons/all-files/go/GoChevronDown';
import { AnimatePresence, motion } from 'framer-motion';

import { Link } from 'gatsby';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import type { NavLink } from '../../../@types/app';
import { navLinks } from '../../../data/nav';
import { useCategories } from '../../../graphql/hooks/use-categories';
import useHandleClose from '../../../hooks/use-handle-close';

const BurgerMenu: FC = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { allStrapiCategory } = useCategories();

  const topBarVariants = {
    open: {
      rotate: 45,
      translateY: 7,
    },
    closed: {
      rotate: 0,
      translateY: 0,
    },
  };

  const middleBarVariants = {
    open: {
      opacity: 0,
    },
    closed: {
      opacity: 1,
    },
  };

  const bottomBarVariants = {
    open: {
      rotate: -45,
      translateY: -7,
    },
    closed: {
      rotate: 0,
      translateY: 0,
    },
  };

  useHandleClose(() => {
    setOpen(false);
  }, ref);

  const products: NavLink[] = [
    {
      href: '/products',
      name: 'PRODUCTS',
      submenu: allStrapiCategory.nodes.map(({ Slug, Title }) => ({
        href: `/${Slug}`,
        name: Title,
      })),
    },
  ];

  return (
    <div className="lg:hidden pb-2" ref={ref}>
      <button
        className="relative w-6 h-4 cursor-pointer z-20"
        onClick={() => {
          setOpen(!open);
        }}
        type="button"
      >
        <motion.div
          animate={open ? 'open' : 'closed'}
          className="absolute w-full h-px bg-action-btn top-0"
          variants={topBarVariants}
        />
        <motion.div
          animate={open ? 'open' : 'closed'}
          className="absolute w-full h-[1.5px] bg-action-btn top-1/2 -translate-y-1/2"
          variants={middleBarVariants}
        />
        <motion.div
          animate={open ? 'open' : 'closed'}
          className="absolute w-full h-px bg-action-btn bottom-0"
          variants={bottomBarVariants}
        />
      </button>

      <AnimatePresence>
        {open ? (
          <motion.div
            animate={{
              right: 0,
              height: 'auto',
              opacity: 1,
            }}
            className="fixed w-full bg-white z-10 top-16 rounded-b-lg shadow-xl"
            exit={{
              right: 0,
              height: 0,
              opacity: 0,
            }}
            initial={{
              right: 0,
              height: 0,
              opacity: 0,
            }}
            transition={{ duration: 0.5 }}
          >
            <ul className="flex flex-col gap-6 items-start mt-8 mb-6 px-4 text-sm">
              {[...products, ...navLinks].map(({ href, name, submenu }) => {
                if (submenu) {
                  return (
                    <li className="w-full" key={name}>
                      <DropdownMenu
                        href={href}
                        name={name}
                        onClick={() => {
                          setOpen(false);
                        }}
                        submenu={submenu}
                      />
                    </li>
                  );
                }

                return (
                  <li className="w-full" key={name}>
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to={href}
                    >
                      {name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default BurgerMenu;

const DropdownMenu: FC<NavLink & { onClick: () => void }> = ({
  name,
  submenu,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  useHandleClose(() => {
    setOpen(false);
  }, ref);

  return (
    <>
      <button
        className="flex gap-1 items-center"
        key={`${open}`}
        onClick={() => {
          setOpen(!open);
        }}
        ref={ref}
        type="button"
      >
        <span>{name}</span>
        <GoChevronDown
          className={`text-md transition-transform  transform ${
            open ? 'rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence mode="wait">
        {submenu && open ? (
          <motion.div
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="mt-2 text-sm w-full">
              {submenu.map(({ href, name: linkName }) => (
                <Link
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full"
                  key={linkName}
                  onClick={() => {
                    onClick();
                  }}
                  to={href}
                >
                  {linkName}
                </Link>
              ))}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};
