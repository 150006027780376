import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

const useHandleClose: <T extends HTMLElement>(
  callback: () => void,
  ref?: MutableRefObject<T | null>,
) => void = (callback, ref) => {
  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (ref?.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    });

    return () => {
      document.removeEventListener('mousedown', () => {
        callback();
      });
    };
  }, [callback, ref]);
};

export default useHandleClose;
