import type { GatsbyBrowser } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type { GlobalPageContext } from './src/@types/app';
import Layout from './src/layout';
import './src/styles/global.css';

export const wrapPageElement: GatsbyBrowser<
  unknown,
  GlobalPageContext
>['wrapPageElement'] = ({ element, props }) => {
  return (   

    <Layout props={props}>
      {element}
      <ToastContainer hideProgressBar theme="colored" />
      
    </Layout>
  
  );
};
