import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { type FC, type ReactNode } from 'react';
import type { GlobalPageContext } from '../@types/app';
import FadePageWrapper from '../components/animation/fade-page';
import Footer from './footer';
import LayoutHeader from './header';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

interface LayoutProps {
  children: ReactNode;
  props: PageProps<unknown, GlobalPageContext>;
}

const Layout: FC<LayoutProps> = ({ children, props }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isBlogDetailPage = location.pathname.includes('/blog');

  return (
    <div className={`${!isBlogDetailPage ? 'overflow-x-hidden' : ''} max-w-[3100px] mx-auto shadow-2xl`}>
      <LayoutHeader />
     

      <main className="mt-20">
        {props.pageContext.disableFadePage ? (
          children
        ) : (
          <FadePageWrapper>{children}</FadePageWrapper>
        )}
      </main>
    
      <Footer />

      <div className="fixed bottom-6 right-6">
        <a
          className="hover:-translate-y-1.5 transition-smooth inline-block"
          href="https://wa.me/919815482343/?text=Hello%20there!"
          rel="noopener noreferrer"
          target="_blank"
        >
          <StaticImage
            alt="..."
            height={48}
            src="../images/whatsapp.png"
            width={48}
          />
        </a>
      </div>
    </div>
  );
};

export default Layout;
