import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Image from '../../../../components/shared/image';
import { useCategories } from '../../../../graphql/hooks/use-categories';
import { useProducts } from '../../../../graphql/hooks/use-products';
import { useSubCategories } from '../../../../graphql/hooks/use-sub-categories';

const ProductsNav: FC = () => {
  const { allStrapiCategory } = useCategories();
  const { allStrapiSubCategory } = useSubCategories();
  const { allStrapiProduct } = useProducts();

  const [category, setCategory] = useState(
    allStrapiCategory.nodes[0].Slug || '',
  );
  const [subCategory, setSubCategory] = useState<string>();
  const [product, setProduct] = useState('');
  const [headerImage, setheaderImage] = useState('');

  const [show, setShow] = useState(false);

  // Set default subcategory
  useEffect(() => {
    setSubCategory(
      allStrapiSubCategory.nodes.filter(
        ({ Category }) => Category?.Slug === category,
      )[0]?.Slug || '',
    );
  }, [allStrapiSubCategory.nodes, category]);

  // Set default product
  useEffect(() => {
    setProduct(
      allStrapiProduct.nodes.filter(
        ({ subCategory: sc }) => sc.Slug === subCategory,
      )[0]?.Slug || '',
    );
  }, [allStrapiProduct.nodes, subCategory]);

  const getHeaderImage = (slg: string) => {
    const findHeader = allStrapiProduct.nodes.filter(
      (item) => item.Slug === slg,
    );
    const hdImage = findHeader[0]?.HeaderImage?.url || '';
    setheaderImage(hdImage);
  };

  useEffect(() => {
    const hdImageslug =
      allStrapiProduct.nodes.filter(
        ({ subCategory: sc }) => sc.Slug === subCategory,
      )[0]?.Slug || '';
    getHeaderImage(hdImageslug);
  }, [allStrapiProduct.nodes, subCategory]);

  return (
    <li
      className={`font-semibold flex items-center transition-smooth
            cursor-pointer relative  underline-offset-[12.5px] decoration-2
            ${show ? 'decoration-[#EE2F2A] underline' : 'hover:decoration-[#EE2F2A] hover:underline'}`}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <div className="flex space-x-1 items-center whitespace-nowrap py-8">
        <span>PRODUCTS</span>

        <MdArrowDropDown className="text-2xl" />
      </div>

      <AnimatePresence>
        {show ? (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute -left-6 top-[5rem] mt-0.5 bg-white  border"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <div className="flex gap-2 px-4 justify-end divide-x">
              <div className="p-4 pb-10">
                <p className="px-2 py-1 font-semibold bg-zinc-300">OPERATIONS</p>
                <div className="grid gap-2 mt-3">
                  {allStrapiCategory.nodes.map(({ Slug, Title }) => {
                    return (
                      <Link
                        className={`whitespace-nowrap py-1.5 font-medium
                     hover:bg-[#EA372C] hover:text-white rounded-md px-2 
                     flex items-center justify-between w-full space-x-6
                     ${category === Slug ? 'bg-[#EA372C] text-white' : ''}`}
                        key={Slug}
                        onClick={() => {
                          setCategory(Slug);
                          setShow(false);
                        }}
                        onMouseEnter={() => {
                          setCategory(Slug);
                        }}
                        to={`/${Slug}/${subCategory}`}
                      >
                        <p className="whitespace-nowrap">{Title}</p>
                        <MdArrowDropDown className="text-2xl -rotate-90" />
                      </Link>
                    );
                  })}
                </div>
              </div>

              <div className="p-4 pb-10">
                <p className="px-2 py-1 font-semibold bg-zinc-300">MACHINES</p>
                <div className="grid gap-2 mt-3">
                  {allStrapiSubCategory.nodes
                    .filter(({ Category }) => Category?.Slug === category)
                    .map(({ Slug, Title, Category }) => {
                      return (
                        <Link
                          className={`whitespace-nowrap py-1.5 font-medium
                     hover:bg-[#EA372C] hover:text-white rounded-md px-2 
                     flex items-center justify-between w-full space-x-6
                     ${subCategory === Slug ? 'bg-[#EA372C] text-white' : ''}`}
                          key={Slug}
                          onClick={() => {
                            setSubCategory(Slug);
                            setShow(false);
                          }}
                          onMouseEnter={() => {
                            setSubCategory(Slug);
                          }}
                          to={`/${Category?.Slug}/${Slug}`}
                        >
                          <p className="whitespace-nowrap">{Title}</p>
                          <MdArrowDropDown className="text-2xl -rotate-90" />
                        </Link>
                      );
                    })}
                </div>
              </div>

              <div className="p-4 pb-10">
                <p className="px-2 py-1 font-semibold bg-zinc-300">MODELS</p>
                <div className="grid gap-2 mt-3">
                  {allStrapiProduct.nodes
                    .filter(({ subCategory: sc }) => sc.Slug === subCategory)
                    .map(({ Slug, Title, subCategory: prodSubCat }) => {
                      return (
                        <Link
                          className={`whitespace-nowrap py-1.5 font-medium
                     hover:bg-[#F7921E] hover:text-white rounded-md px-2 w-full text-left
                     ${product === Slug ? 'bg-[#F7921E] text-white' : ''}`}
                          key={Slug}
                          onClick={() => {
                            setProduct(Slug);
                            setShow(false);
                          }}
                          onMouseEnter={() => {
                            setProduct(Slug);
                            getHeaderImage(Slug);
                          }}
                          to={`/${prodSubCat.Category.Slug}/${prodSubCat.Slug}/${Slug}`}
                        >
                          <p className="whitespace-nowrap">{Title}</p>
                        </Link>
                      );
                    })}
                </div>
              </div>

              <div className="px-4 w-72 pt-12">
                <Link
                  onClick={() => {
                    setShow(false);
                  }}
                  to={`/${category}/${subCategory}/${product}`}
                >
                  {/* <Image
                    className="w-72 h-72 aspect-square object-left-top"
                    height={512}
                    key={product}
                    src={
                      allStrapiProduct.nodes.find(
                        ({ Slug }) => Slug === product,
                      )?.Thumbnail.url || ''
                    }
                    width={512}
                  /> */}
                  {headerImage ? (
                    <Image
                      className="w-72 h-72 aspect-square object-left-top"
                      height={512}
                      key={product}
                      src={headerImage}
                      width={512}
                    />
                  ) : (
                    <div className="NoImagePrev" />
                  )}
                </Link>
              </div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </li>
  );
};

export default ProductsNav;
