import { motion } from 'framer-motion';
import type { CSSProperties, ReactNode } from 'react';

export default function ModalWrapper({
  className,
  children,
  style,
}: {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
}): JSX.Element {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={
        className ||
        `fixed top-0 left-0 w-full
       h-full z-50 flex justify-center items-center backdrop-blur-sm bg-blue/5`
      }
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      style={style}
      transition={{ duration: 0.8 }}
    >
      {children}
    </motion.div>
  );
}
