import { AnimatePresence } from 'framer-motion';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export interface ModalProps {
  children?: ReactNode;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  disableScroll?: boolean;
  shouldCloseOnBackDrop?: boolean;
}

const Modal: FC<ModalProps> = ({
  children,
  showModal,
  disableScroll = true,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const selector = randomString(7, 'portal-');

    const _el = document.createElement('div');

    if (selector && !ref.current) {
      _el.id = selector;

      document.body.appendChild(_el);
      ref.current = _el;
    }

    return () => {
      ref.current = null;
      document.body.removeChild(_el);
    };
  }, []);

  useEffect(() => {
    if (disableScroll) {
      if (showModal) {
        document.body.classList.add('no-scroll');
      }
    }

    if (!showModal) {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [disableScroll, showModal]);

  if (!ref.current) {
    return null;
  }

  return createPortal(
    <AnimatePresence mode="wait">
      {showModal ? children : null}
    </AnimatePresence>,
    ref.current,
  );
};

export default Modal;

export function randomString(length: number, prefix = ''): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return prefix + result.toLowerCase();
}
