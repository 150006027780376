import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import type { FC } from 'react';
import { useState } from 'react';
import type { NavLink } from '../../../@types/app';

const Submenu: FC<NavLink> = ({ name, submenu }) => {
  const [show, setShow] = useState(false);

  return (
    <li
      className={`font-semibold flex items-center transition-smooth
            cursor-pointer relative underline-offset-[12.5px] decoration-2
            ${show ? 'decoration-[#EE2F2A] underline' : 'hover:decoration-[#EE2F2A] hover:underline'}`}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <div className="flex space-x-1 items-center whitespace-nowrap py-8">
        <span>{name}</span>

        <MdArrowDropDown className="text-2xl" />
      </div>

      <AnimatePresence>
        {show ? (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute -left-6 top-[5rem] pt-0.5 w-96"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <ul className="py-2 px-6 bg-white w-full border">
              {submenu?.map((menu) => {
                return (
                  <li
                    className="whitespace-nowrap py-1.5 font-medium
                     hover:bg-[#EA372C] hover:text-white w-fit rounded-md my-2"
                    key={menu.href}
                  >
                    <Link
                      className="w-full block px-3 "
                      onClick={() => {
                        setShow(false);
                      }}
                      to={menu.href}
                    >
                      {menu.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </li>
  );
};

export default Submenu;
