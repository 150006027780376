import { AnimatePresence, motion } from 'framer-motion';

import { useLocation } from '@reach/router';
import type { FC, ReactNode } from 'react';

const FadePageWrapper: FC<{
  children: ReactNode;
  durationInSec?: number;
}> = ({ children, durationInSec = 0.5 }) => {
  const { pathname } = useLocation();

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
        initial={{ opacity: 0 }}
        key={pathname}
        transition={{ duration: durationInSec }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default FadePageWrapper;
