import { graphql, useStaticQuery } from 'gatsby';

interface ReturnType {
  allStrapiSubCategory: {
    nodes: {
      Title: string;
      Slug: string;
      Category?: {
        Slug: string;
      };
    }[];
  };
}

export const useSubCategories: () => ReturnType = () => {
  const data = useStaticQuery<ReturnType>(graphql`
    query AllSubCategoriesQuery {
      allStrapiSubCategory(sort: { position: ASC }) {
        nodes {
          Title
          Slug
          Category {
            Slug
          }
        }
      }
    }
  `);

  return data;
};
