/* eslint-disable jsx-a11y/alt-text */
'use client';

import type { CSSProperties, FC } from 'react';
import { useEffect, useRef, useState } from 'react';

export interface ImageProps {
  height?: number | string;
  width?: number | string;
  className?: string;
  placeholder?: string;
  src: string;
  alt?: string;
  threshold?: number;
  containerStyle?: CSSProperties;
}

const Image: FC<ImageProps> = ({
  className = '',
  height = '100%',
  width = '100%',
  src,
  placeholder,
  alt = '...',
  threshold = 0.7,
  containerStyle,
}) => {
  const [shouldLoad, setShouldLoad] = useState<boolean>(false);
  const viewContainerRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!isCancelled) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !isLoaded) {
              setShouldLoad(true);
            }
          });
        },
        { threshold },
      );

      if (viewContainerRef.current) {
        observer.observe(viewContainerRef.current);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [isLoaded, threshold]);

  return (
    <div ref={viewContainerRef} style={containerStyle}>
      {shouldLoad && !isLoaded ? (
        <div className="animate-fade-in">
          {placeholder ? (
            <img
              alt="loading_placeholder"
              className="blur-sm"
              height={height}
              src={placeholder}
              width={width}
            />
          ) : (
            <img
              className={`skeleton ${className}`}
              height={height}
              width={width}
            />
          )}
        </div>
      ) : (
        ''
      )}

      {shouldLoad ? (
        <div className="w-full h-full">
          <img
            alt={alt}
            className={isLoaded ? className : ''}
            height={isLoaded ? height : 0}
            onError={() => {
              setIsLoaded(true);
            }}
            onLoad={() => {
              setIsLoaded(true);
            }}
            src={src}
            width={isLoaded ? width : 0}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Image;
