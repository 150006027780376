import { Link } from 'gatsby';
import type { FC } from 'react';
import FooterLinks from './links';
import { useLocation } from '@reach/router';
import RequestForm from '../../components/Request-form';
import { useState } from 'react';

const Footer: FC = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  return (
    <footer className="footer-bg pt-10">
      <div className="fcbg fcbgTop" />
      {pathname != '/contact-us/' && pathname != '/thank-you/' && <RequestForm show={show} setShow={setShow} />}

      <div className="container mx-auto px-6">
        <FooterLinks />

        {/* Uncomment and modify the sections below if needed */}
        {/* 
        <div
          className="flex md:flex-row flex-col justify-between items-center gap-2 w-full pb-6
         text-gray-50 font-light text-sm"
        >
          <p>
            Designed and Developed By{' '}
            <a
              className="font-semibold"
              href="https://www.zartek.in"
              rel="noreferrer"
              target="_blank"
            >
              Zartek
            </a>
          </p>
        </div>

        <hr className="my-6 opacity-30" />
        <div className="flex justify-center pb-6 text-gray-50 font-light">
          <a
            className="text-gray-50 transition-smooth hover:text-gray-200"
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            className="text-gray-50 transition-smooth hover:text-gray-200"
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </div>
        */}
      </div>
      <div className="footer-bottom fcbg text-gray-50 font-light text-sm py-3">
        <div className="container mx-auto px-6">
          <div className="grid lg:grid-cols-2 lg:gap-2 gap-6 space-x-0 md:space-x-4">
            <Link
              className="text-gray-50 transition-smooth hover:text-gray-200 text-left"
              to="/terms-and-conditions"
            >
              Terms & Conditions
            </Link>
            <p className="text-right max-sm:ml-0 max-sm:text-left">
              © Copyright {new Date().getFullYear()}. Sahil Alloys & Machine
              Tools
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
