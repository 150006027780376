import { graphql, useStaticQuery } from 'gatsby';

interface ReturnType {
  allStrapiCategory: {
    nodes: {
      Title: string;
      Slug: string;
    }[];
  };
}

export const useCategories: () => ReturnType = () => {
  const data = useStaticQuery<ReturnType>(graphql`
    query AllCategories {
      allStrapiCategory(limit: 10, sort: { position: ASC }) {
        nodes {
          Title
          Slug
        }
      }
    }
  `);

  return data;
};
