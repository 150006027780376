export const submitContactRequest = `
  mutation ContactRequestForm($data: ContactRequestInput!) {
    createContactRequest(data: $data) {
      data {
        id
      }
    }
  }
`;

export const submitEnquiryRequest = `
  mutation EnquirySubmit($data: EnquiryInput!) {
    createEnquiry(data: $data) {
      data {
        id
      }
    }
  }
`;

export const brochureRequest = `
mutation CreatBrochure($dto:BrochureInput!){
  createBrochure(data:$dto){
    data{
      id
    }
  }
}
`;
