import { graphql, useStaticQuery } from 'gatsby';

interface ReturnType {
  allStrapiProduct: {
    nodes: {
      Title: string;
      Slug: string;
      Thumbnail: {
        url: string;
      };
      subCategory: {
        Slug: string;
        Category: {
          Slug: string;
        };
      };
      HeaderImage: {
        url: string;
      };
    }[];
  };
}

export const useProducts: () => ReturnType = () => {
  const data = useStaticQuery<ReturnType>(graphql`
    query AllProductsQuery {
      allStrapiProduct(
        filter: { Category: { Slug: { ne: null } } }
        limit: 1000
        sort: { position: ASC }
      ) {
        nodes {
          Title
          Slug
          Thumbnail {
            url
          }
          subCategory: Category {
            Slug
            Category {
              Slug
            }
          }
          HeaderImage {
            url
          }
        }
      }
    }
  `);

  return data;
};
